/**
 * @file config.js
 * @author Constantinos Davanos
 * @TODO The config options for Apple devices/os's shouldn't be `isXYZ`.
 *       They were put in on the fly and expected to be removed.
 *       If the need to keep them remains, they should be renamed.
 */

// OLD REVGEN CONFIG
import mergeWith from "lodash.mergewith";
import "url-polyfill";

export const affiliateData = {
  domain: (loc => {
    const data = (window || {}).adiData || {};
    const domain = new URL(
      (data.preview && data.affiliate && data.entryUrl) ||
        (!data.preview &&
          data.affiliate &&
          data.entryUrl &&
          data.rgProduct &&
          data.rgProduct.toLowerCase() === "fbia")
        ? data.entryUrl
        : window.location.href
    );
    // split up the hostname
    const domainParts = domain.hostname.split(".");
    const domainPartsTest = domainParts.length === 3;
    domain.sub = domainPartsTest ? domainParts[0] : "";
    domain.dom = domainPartsTest ? domainParts[1] : domainParts[0];
    domain.tld = domainPartsTest ? domainParts[2] : domainParts[1];
    domain.id = `${domain.dom}.${domain.tld}`;

    // modification for localhost tests - fix in jest later - kg
    if (domain.dom === 'localhost') {
      domain.sub = "www-uat";
      domain.dom = "lehighvalleylive";
      domain.tld = "com";
      domain.id = "lehighvalleylive.com"
    }

    return domain;
  })()
};

let debugSession = "";
try {
  debugSession =
    window.sessionStorage.getItem("adsdebug") ||
    window.document.cookie.match(new RegExp("(^| )adsdebug=([^;]+)"));
} catch (e) {
  console.log(e);
}

export const adDebug = window.location.hash.indexOf("addebug") === -1;

const defaultLogLevel =
  process.env.NODE_ENV !== "development-local" && adDebug && !debugSession
    ? "warn"
    : "debug";

const defaultConfig = {
  adsEnabled: true,
  autoload: true,
  apstagEnabled: false,
  debuggerLinkEnabled: false,
  logLevel: defaultLogLevel,
  inserter: {
    logLevel: defaultLogLevel
  },
  api: {
    logLevel: defaultLogLevel
  },
  units: {
    logLevel: defaultLogLevel
  },
  requester: {
    logLevel: defaultLogLevel
  },
  sources: {
    /*lotame: {
      logLevel: defaultLogLevel,
    },*/
    embeddedTags: {
      logLevel: defaultLogLevel
    }
  }
};

export let config = defaultConfig;
/**
 * Sets the global config
 * @param {object} newConfig - The config that you want to become the global config.
 */

export const addToConfig = data => {
  config = mergeWith({}, config, data);
  return config;
};

// END OLD REVGEN CONFIG

// NEW Config variables.  Keep the exports alphabetically sorted.

/**
 * breakpoints contains numerical pixel values at which we engage the breakpoints
 * @type {Object}
 */
export const breakpoints = {
  mobile: 320,
  mobile_w: 480,
  tablet: 730,
  tablet_w: 768,
  desktop: 980,
  desktop_w: 1024
};

export const biddableSizes = [
  [300, 250],
  [300, 600],
  [320, 50],
  [728, 90],
  [970, 250],
  [970, 90]
];

export const biddableSizesPrebidAddons = [
  [325, 508],
  [325, 204],
  [620, 366]
];

/**
 * isObitIframe detects if the user is on the obits subdomain and if so, don't
 * use the width of the iframe, use the width of the top window.
 */
var isObitIframe = function isObitIframe() {
  var subdomain = 'obits';
  var url = new URL(window.top.document.URL);
  return url.host.startsWith(subdomain);
};

/**
 * isMobile Detects if the user is on a mobile device and is compliant with the
 * MDN's recommend detection method....
 * @returns {boolean} - True if mobile, false if not.
 */
export const isMobile = () => {
  // note: tried to remove the try catch and fmp time went up - in case anyone else is thinking about this
  // document.documentElement.clientWidth - is needed to keep fmp down
  let width;

  try {
    if (isObitIframe() === true) {
      width = window.top.document.documentElement.clientWidth;
    } else {
      width = document.documentElement.clientWidth || window.innerWidth;
    }
  } catch (e) {
    console.warn(e);
  } finally {
    width = width || document.documentElement.clientWidth || window.innerWidth;
    return width <= breakpoints.tablet;
  }
};

/**
 * getPlatform gets the platform type as a string.
 * @return {string} mobile or desktop
 */
export const getPlatform = () => (isMobile() ? "mobile" : "desktop");

const modifySlotMapSizesForDesktop =
  document.documentElement.clientWidth >= 990 && getPlatform() === "desktop";

/**
 * dfpMap is an object representing the dfp state for each property
 * @type {Object}
 */
export const dfpMap = {
  networkID: "344101295", // advance network dfp id
  default: {
    enabled: true,
    abbrev: "",
    rubiconAdsEnabled: false,
    amznAdsEnabled: false,
    lotameId: ""
  },
  "lonestarlive.com": {
    enabled: true,
    abbrev: "LS",
    amznAdsEnabled: true,
    rubiconAdsEnabled: true,
    lotameId: "17325",
    useSafeFrames: true
  },
  "hereisoregon.com": {
    enabled: true,
    abbrev: "OR",
    amznAdsEnabled: false,
    rubiconAdsEnabled: false,
    lotameId: "16433",
    useSafeFrames: true
  },
  "reckon.news": {
    enabled: true,
    abbrev: "AL",
    amznAdsEnabled: false,
    rubiconAdsEnabled: false,
    lotameId: "16619",
    useSafeFrames: true
  },
  "al.com": {
    enabled: true,
    abbrev: "AL",
    amznAdsEnabled: true,
    rubiconAdsEnabled: true,
    lotameId: "958",
    useSafeFrames: true
  },
  "cleveland.com": {
    enabled: true,
    abbrev: "CL",
    amznAdsEnabled: true,
    rubiconAdsEnabled: true,
    lotameId: "959",
    useSafeFrames: true
  },
  "gulflive.com": {
    enabled: true,
    abbrev: "GL",
    amznAdsEnabled: true,
    rubiconAdsEnabled: true,
    lotameId: "960",
    useSafeFrames: true
  },
  "lehighvalleylive.com": {
    enabled: true,
    abbrev: "LV",
    amznAdsEnabled: true,
    rubiconAdsEnabled: true,
    lotameId: "961",
    useSafeFrames: true
  },
  "masslive.com": {
    enabled: true,
    abbrev: "MA",
    amznAdsEnabled: true,
    rubiconAdsEnabled: true,
    lotameId: "962",
    useSafeFrames: true
  },
  "mlive.com": {
    enabled: true,
    abbrev: "MI",
    amznAdsEnabled: true,
    rubiconAdsEnabled: true,
    lotameId: "963",
    useSafeFrames: true
  },
  "newyorkupstate.com": {
    enabled: true,
    abbrev: "NY",
    amznAdsEnabled: true,
    rubiconAdsEnabled: true,
    lotameId: "6487",
    useSafeFrames: true
  },
  "nj.com": {
    enabled: true,
    abbrev: "NJ",
    amznAdsEnabled: true,
    rubiconAdsEnabled: true,
    lotameId: "937",
    useSafeFrames: true
  },
  "oregonlive.com": {
    enabled: true,
    abbrev: "OR",
    amznAdsEnabled: true,
    rubiconAdsEnabled: true,
    lotameId: "965",
    useSafeFrames: true
  },
  "pennlive.com": {
    enabled: true,
    abbrev: "PN",
    amznAdsEnabled: true,
    rubiconAdsEnabled: true,
    lotameId: "966",
    useSafeFrames: true
  },
  "silive.com": {
    enabled: true,
    abbrev: "SI",
    amznAdsEnabled: true,
    rubiconAdsEnabled: true,
    lotameId: "967",
    useSafeFrames: true
  },
  "syracuse.com": {
    enabled: true,
    abbrev: "SY",
    amznAdsEnabled: true,
    rubiconAdsEnabled: true,
    lotameId: "968",
    useSafeFrames: true
  },
  "thisiscny.com": {
    enabled: true,
    abbrev: "SY",
    amznAdsEnabled: true,
    rubiconAdsEnabled: true,
    lotameId: "17473",
    useSafeFrames: true
  },
};

// A map of all the dpf sizes available.  No use for this yet.
export const dfpSizes = [
  [1, 1],
  [88, 31],
  [300, 600],
  [170, 30],
  [120, 60],
  [970, 250],
  [320, 50],
  [728, 90],
  [300, 250],
  [970, 90],
  [300, 1050],
  "fluid"
];

export const dfpSlotMap = {
  // MOBILE AD SLOTS
  mobile: {
    // InFeed_Promo
    InFeed_Promo: {
      priority: 100,
      refresh_rate: {
        'homepage-beta': 0
      },
      refresh_count: {
        'homepage-beta': 0
      },
      sizes: [[300, 251], "fluid"],
      slotTargeting: {
        rg_adslot: "InFeed_Promo",
        rg_atf: false,
        rg_iab: true
      }
    },
    // AboveFeed_Center
    AboveFeed_Center: {
      priority: 100,
      refresh_rate: {
        all: 0
      },
      refresh_count: {
        all: 0
      },
      sizes: [["fluid"], [320, 1067]],
      slotTargeting: {
        rg_adslot: "AboveFeed_Center",
        rg_atf: false,
        rg_iab: false
      }
    },
    //InFeed_Center_Wide_Adhesion
    InFeed_Center_Wide_Adhesion: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[320, 51]],
      slotTargeting: {
        rg_adslot: "InFeed_Center_Wide_Adhesion",
        rg_atf: true,
        rg_iab: true
      }
    },
    // Window_Bottom
    Window_Bottom: {
        priority: 100,
        refresh_rate: {
          all: 30,
        },
        refresh_count: {
          all: 20,
        },
        sizes: [[320, 50], [1, 1], 'fluid'],
        slotTargeting: {
          rg_adslot: 'Window_Bottom',
          rg_atf: true,
          rg_iab: true,
        },
      },
    // InContent_Center
    InContent_Center: {
      priority: 100,
      refresh_rate: { all: 35 },
      refresh_count: { all: 10 },
      sizes: [[300, 250], [325, 508], [325, 204], [1, 1], "fluid"],
      slotTargeting: {
        rg_adslot: "InContent_Center",
        rg_atf: false,
        rg_iab: true
      }
    },
    // InContent_Center_Wide
    InContent_Center_Wide: {
      priority: 100,
      refresh_rate: { all: 35 },
      refresh_count: { all: 10 },
      sizes: [[300, 250], [325, 508], [325, 204], "fluid"],
      slotTargeting: {
        rg_adslot: "InContent_Center_Wide",
        rg_atf: false,
        rg_iab: true
      }
    },
    // InContent_Native
    InContent_Native: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[300, 250], [1, 1], "fluid"],
      slotTargeting: {
        rg_adslot: "InContent_Native",
        rg_atf: false,
        rg_iab: true
      }
    },
    // InContent_Video
    InContent_Video: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[300, 250], [1, 1], "fluid"],
      slotTargeting: {
        rg_adslot: "InContent_Video",
        rg_atf: false,
        rg_iab: true
      }
    },
    // InFeed_Center
    InFeed_Center: {
      priority: 100,
      refresh_rate: { 'homepage-beta': 35 },
      refresh_count: { 'homepage-beta': 10 },
      sizes: [[300, 250], [325, 508], [325, 204], "fluid"],
      slotTargeting: {
        rg_adslot: "InFeed_Center",
        rg_atf: false,
        rg_iab: true
      }
    },
    //InFeed_Center_Wide
    InFeed_Center_Wide: {
      priority: 100,
      refresh_rate: { 'homepage-beta': 35 },
      refresh_count: { 'homepage-beta': 10 },
      sizes: [[300, 250], [325, 508], [325, 204], "fluid"],
      slotTargeting: {
        rg_adslot: "InFeed_Center_Wide",
        rg_atf: false,
        rg_iab: true
      }
    },
    // InFeed_Native_B - new homepage B tiles
    InFeed_Native_B: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[300, 250], "fluid"],
      slotTargeting: {
        rg_adslot: "InFeed_Native_B",
        rg_atf: false,
        rg_iab: true
      }
    },
    // InFeed_Native
    InFeed_Native: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [["fluid"],[320,1067]],
      slotTargeting: {
        rg_adslot: "InFeed_Native",
        rg_atf: false,
        rg_iab: false
      }
    },
    // InFeed_Native_C - new homepage C tiles
    InFeed_Native_C: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [["fluid"]],
      slotTargeting: {
        rg_adslot: "InFeed_Native_C",
        rg_atf: false,
        rg_iab: false
      }
    },
    // InterArticle_Center
    InterArticle_Center: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [["fluid"]],
      slotTargeting: {
        rg_adslot: "InterArticle_Center",
        rg_atf: false,
        rg_iab: false
      }
    },
    // Popup
    Popup: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[1, 1]],
      slotTargeting: {
        rg_adslot: "Popup",
        rg_atf: false,
        rg_iab: false
      }
    },
    // TopRail_Sponsor
    TopRail_Sponsor: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [["fluid"]],
      slotTargeting: {
        rg_adslot: "TopRail_Sponsor",
        rg_atf: true,
        rg_iab: false
      }
    },
    // TopRail_Above
    TopRail_Above: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[320, 50]],
      slotTargeting: {
        rg_adslot: "TopRail_Above",
        rg_atf: true,
        rg_iab: true
      }
    }
  },
  // DESKTOP AD SLOTS
  desktop: {
    // InFeed_Promo
    InFeed_Promo: {
      priority: 100,
      refresh_rate: {
        'homepage-beta': 0
      },
      refresh_count: {
        'homepage-beta': 0
      },
      sizes: [[300, 251], "fluid"],
      slotTargeting: {
        rg_adslot: "InFeed_Promo",
        rg_atf: false,
        rg_iab: true
      }
    },
    // AboveFeed_Center
    AboveFeed_Center: {
      priority: 100,
      refresh_rate: {
        all: 0
      },
      refresh_count: {
        all: 0
      },
      sizes: [["fluid"], [540, 1800]],
      slotTargeting: {
        rg_adslot: "AboveFeed_Center",
        rg_atf: false,
        rg_iab: false
      }
    },
    // FindLocal_Widget
    FindLocal_Widget: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[3, 1]],
      slotTargeting: {
        rg_adslot: "FindLocal_Widget",
        rg_atf: false,
        rg_iab: false
      }
    },
    // Window_Bottom
    Window_Bottom: {
        priority: 100,
        refresh_rate: {
          all: 30,
        },
        refresh_count: {
          all: 20,
        },
        sizes: [[728, 90], [1, 1], 'fluid'],
        slotTargeting: {
          rg_adslot: 'Window_Bottom',
          rg_atf: true,
          rg_iab: true,
        },
      },
    // DropDownMenu_Sponsor
    DropDownMenu_Sponsor: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[170, 50]],
      slotTargeting: {
        rg_adslot: "DropDownMenu_Sponsor",
        rg_atf: false,
        rg_iab: false
      }
    },
    // InContent_Center
    InContent_Center: {
      priority: 100,
      refresh_rate: { all: 35 },
      refresh_count: { all: 10 },
      sizes: [[300, 250], [620, 366], [1, 1], "fluid"],
      slotTargeting: {
        rg_adslot: "InContent_Center",
        rg_atf: false,
        rg_iab: true
      }
    },
    // InContent_Center_Wide
    InContent_Center_Wide: {
      priority: 100,
      refresh_rate: { all: 35 },
      refresh_count: { all: 10 },
      sizes: modifySlotMapSizesForDesktop ? [[728, 90], [970, 250], [970, 90], [620, 366], "fluid"] : [[728, 90], [620, 366], "fluid"],
      slotTargeting: {
        rg_adslot: "InContent_Center_Wide",
        rg_atf: false,
        rg_iab: true
      }
    },
    // 'InContent_Left'
    InContent_Left: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[300, 250]],
      slotTargeting: {
        rg_adslot: "InContent_Left",
        rg_atf: false,
        rg_iab: true
      }
    },
    // InContent_Native
    InContent_Native: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[300, 250], [1, 1], "fluid"],
      slotTargeting: {
        rg_adslot: "InContent_Native",
        rg_atf: false,
        rg_iab: true
      }
    },
    // 'InContent_Right'
    InContent_Right: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[300, 250]],
      slotTargeting: {
        rg_adslot: "InContent_Right",
        rg_atf: false,
        rg_iab: true
      }
    },
    // InContent_Video
    InContent_Video: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[300, 250], [1, 1], "fluid"],
      slotTargeting: {
        rg_adslot: "InContent_Video",
        rg_atf: false,
        rg_iab: true
      }
    },
    // InFeed_Center
    InFeed_Center: {
      priority: 100,
      refresh_rate: { 'homepage-beta': 35 },
      refresh_count: { 'homepage-beta': 10 },
      sizes: [[300, 250], "fluid"],
      slotTargeting: {
        rg_adslot: "InFeed_Center",
        rg_atf: false,
        rg_iab: true
      }
    },
    // InFeed_Center_728
    InFeed_Center_728: {
      priority: 100,
      refresh_rate: { all: 30 },
      refresh_count: { all: 10 },
      sizes: [[728, 90]],
      slotTargeting: {
        rg_adslot: "InFeed_Center_728",
        rg_atf: false,
        rg_iab: true
      }
    },
    // InFeed_Left
    InFeed_Left: {
      priority: 100,
      refresh_rate: { 'homepage-beta': 35 },
      refresh_count: { 'homepage-beta': 10 },
      sizes: [[300, 250]],
      slotTargeting: {
        rg_adslot: "InFeed_Left",
        rg_atf: false,
        rg_iab: true
      }
    },
    // InFeed_Native
    InFeed_Native: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [["fluid"],[540,1800]],
      slotTargeting: {
        rg_adslot: "InFeed_Native",
        rg_atf: false,
        rg_iab: false
      }
    },
    //InFeed_Native_B - homepage B tiles
    InFeed_Native_B: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[300, 250], "fluid"],
      slotTargeting: {
        rg_adslot: "InFeed_Native_B",
        rg_atf: false,
        rg_iab: true
      }
    },
    // InFeed_Native_C new homepage C tiles
    InFeed_Native_C: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [["fluid"]],
      slotTargeting: {
        rg_adslot: "InFeed_Native_C",
        rg_atf: false,
        rg_iab: false
      }
    },
    // InFeed_Right
    InFeed_Right: {
      priority: 100,
      refresh_rate: { 'homepage-beta': 35 },
      refresh_count: { 'homepage-beta': 10 },
      sizes: [[300, 250]],
      slotTargeting: {
        rg_adslot: "InFeed_Right",
        rg_atf: false,
        rg_iab: true
      }
    },
    // InFeed_Sponsor
    InFeed_Sponsor: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[632, 38]],
      slotTargeting: {
        rg_adslot: "InFeed_Sponsor",
        rg_atf: false,
        rg_iab: false
      }
    },
    // InFeed_Center_Wide
    InFeed_Center_Wide: {
      priority: 100,
      refresh_rate: { 'homepage-beta': 35 },
      refresh_count: { 'homepage-beta': 10 },
      sizes: modifySlotMapSizesForDesktop
        ? [[728, 90], [970, 250], [970, 90]]
        : [[728, 90]],
      slotTargeting: {
        rg_adslot: "InFeed_Center_Wide",
        rg_atf: false,
        rg_iab: true
      }
    },
    // InFeed_Center_Wide_Adhesion
    InFeed_Center_Wide_Adhesion: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: modifySlotMapSizesForDesktop ? [[728, 91], [970, 91]] : [[728, 91]],
      slotTargeting: {
        rg_adslot: "InFeed_Center_Wide_Adhesion",
        rg_atf: true,
        rg_iab: true
      }
    },
    // InterArticle_Center
    InterArticle_Center: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [["fluid"]],
      slotTargeting: {
        rg_adslot: "InterArticle_Center",
        rg_atf: false,
        rg_iab: false
      }
    },
    // Popup
    Popup: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[1, 1]],
      slotTargeting: {
        rg_adslot: "Popup",
        rg_atf: false,
        rg_iab: false
      }
    },
    // RightRail_Badge1
    RightRail_Badge1: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[120, 60]],
      slotTargeting: {
        rg_adslot: "RightRail_Badge1",
        rg_atf: false,
        rg_iab: false
      }
    },
    // RightRail_Badge2
    RightRail_Badge2: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[120, 60]],
      slotTargeting: {
        rg_adslot: "RightRail_Badge2",
        rg_atf: false,
        rg_iab: false
      }
    },
    // RightRail_Badge3
    RightRail_Badge3: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[120, 60]],
      slotTargeting: {
        rg_adslot: "RightRail_Badge3",
        rg_atf: false,
        rg_iab: false
      }
    },
    // RightRail_Badge4
    RightRail_Badge4: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[120, 60]],
      slotTargeting: {
        rg_adslot: "RightRail_Badge4",
        rg_atf: false,
        rg_iab: false
      }
    },
    // RightRail_Badge5
    RightRail_Badge5: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[120, 60]],
      slotTargeting: {
        rg_adslot: "RightRail_Badge5",
        rg_atf: false,
        rg_iab: false
      }
    },
    // RightRail_Badge6
    RightRail_Badge6: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[120, 60]],
      slotTargeting: {
        rg_adslot: "RightRail_Badge6",
        rg_atf: false,
        rg_iab: false
      }
    },
    // RightRail_Bottom
    RightRail_Bottom: {
      priority: 100,
      refresh_rate: { all: 30 },
      refresh_count: { all: 10 },
      sizes: [[300, 250], [300, 600], "fluid"],
      slotTargeting: {
        rg_adslot: "RightRail_Bottom",
        rg_atf: false,
        rg_iab: true
      }
    },
    // RightRail_Middle
    RightRail_Middle: {
      priority: 100,
      refresh_rate: { all: 30 },
      refresh_count: { all: 10 },
      sizes: [[300, 250], "fluid"],
      slotTargeting: {
        rg_adslot: "RightRail_Middle",
        rg_atf: false,
        rg_iab: true
      }
    },
    // RightRail_Sponsor
    RightRail_Sponsor: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[300, 65]],
      slotTargeting: {
        rg_adslot: "RightRail_Sponsor",
        rg_atf: false,
        rg_iab: false
      }
    },
    // RightRail_Top
    RightRail_Top: {
      priority: 100,
      refresh_rate: { 'cal': 30, 'index': 30, 'gallery': 30, 'multimedia': 30, 'gallery-embed': 30 },
      refresh_count: { 'cal': 10, 'index': 10, 'gallery': 10, 'multimedia': 10, 'gallery-embed': 10 },
      sizes: [[300, 250], [300, 600], [300, 1050], "fluid"],
      slotTargeting: {
        rg_adslot: "RightRail_Top",
        rg_atf: true,
        rg_iab: true
      }
    },
    // TopRail_Above
    TopRail_Above: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[728, 90]],
      slotTargeting: {
        rg_adslot: "TopRail_Above",
        rg_atf: true,
        rg_iab: true
      }
    },
    // TopRail_Below
    TopRail_Below: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[970, 90], [970, 250]],
      slotTargeting: {
        rg_adslot: "TopRail_Below",
        rg_atf: true,
        rg_iab: true
      }
    },
    // TopRail_Below_Flex
    TopRail_Below_Flex: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: modifySlotMapSizesForDesktop
        ? [[728, 90], [970, 250], [970, 90]]
        : [[728, 90]],
      slotTargeting: {
        rg_adslot: "TopRail_Below_Flex",
        rg_atf: true,
        rg_iab: true
      }
    },
    // TopRail_Sponsor
    TopRail_Sponsor: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [["fluid"]],
      slotTargeting: {
        rg_adslot: "TopRail_Sponsor",
        rg_atf: true,
        rg_iab: false
      }
    },
    // Wallpaper
    Wallpaper: {
      priority: 100,
      refresh_rate: { all: 0 },
      refresh_count: { all: 0 },
      sizes: [[2, 1]],
      slotTargeting: {
        rg_adslot: "Wallpaper",
        rg_atf: true,
        rg_iab: false
      }
    }
  }
};

export const domainWhiteList = [];

export const adDensityMap = {
  infrequentVisitors: {              // The new normal
    mobile: '110',
    desktop: '80',
  },
  frequentVisitors: {          // recognized as frequent visitors, ie 3+ PVs in the last month
    mobile: '130',
    desktop: '100',
  }
};
