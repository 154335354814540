/**
 * @file embeddedTags.js
 * @author Ken Egbuna
 */
import Target from "./target";
import {
  createObjectFromQuery,
  getQueryString,
  affiliateAbbrev,
  getLocalStorageValue,
} from "../utils";
import {
  affiliateData,
  getPlatform,
  adDensityMap,
} from "../config/config";
import { adLogger } from "../loggerV2";

/**
 * A appendTargeting data class for gathering values from the query string and appendTargeting value
 *
 * update: 9/6/2018
 * window.m_entry_tags => adiData.entryTags
 * window.m_entry_id => adiData.entryId
 * window.m_hssn_schoolids => adiData.hssnSchoolIds
 * window.m_gallery_id => adiData.galleryId
 * window.m_hssn_sport => adiData.hssnSport
 * window.rg_product => adiData.rgProduct
 * window.rg_version => adiData.rgVersion
 * window.rg_aux => adiData.auxUid
 * window.rg_topic => adiData.topicsUid
 * update 11/8/2018
 * window.m_page_type => adiData.pageType
 */
export default class EmbeddedTags extends Target {
  constructor(window, config) {
    super(window);
    this.embedConfig = config || {};
  }

  /**
   * Initializes the currentQueryString instance and gathers values from the
   * window location search field. Also grabs appendTargeting if available
   * @returns {Promise} A promise that resolves with the data property or rejects with a reason
   */
  init() {
    return new Promise((resolve, reject) => {
      if (
        !affiliateData.domain ||
        typeof ((affiliateData.domain || {}).search || "") !== "string"
      ) {
        return reject(
          new Error(
            "The Window object is missing either location or location.search."
          )
        );
      }

      // fetch reg_id and stores in local storage as early as possible for ads (starting bc watch event)
      fetchRegId();

      const rgProduct = this.getProduct();
      const rgPv = setPersistentKeyValue('rg_pv'); // initiate function for persistent key value for pageViews
      const rgCv = rgProduct[0] === 'cal' && setPersistentKeyValue('rg_cv'); // initiate function for persistent key value for contentViews on cal pages only
      const entryTags = this.getEntryTags();
      const entryIdTag = this.getEntryIdTag();
      const authorTag = this.getAuthorTag();
      const galTag = this.getGalleryIdTag();
      const rgVersion = this.getVersion();
      const rgPageType = this.getPageType();
      const rgFbwv = this.getFbwv();

      // Only get Clavis values on article pages - 'cal' - REVGEN-843
      const rgAux = rgProduct[0] === 'cal' && this.getAux();
      const rgTopic = rgProduct[0] === 'cal' && this.getTopic();
      const rg_cat = rgProduct[0] === 'cal' && this.getCategories();
      const rg_ent = rgProduct[0] === 'cal' && this.getEntities();
      const rg_ccat = rgProduct[0] === 'cal' && this.getCustomCategories();
      const rg_cent = rgProduct[0] === 'cal' && this.getCustomEntities();

      // if an appendTargeting exists append it to the default querystring
      // maintaining backwards comaptibility with old pages that contain the OASappendquery value on the window
      let queryString = getQueryString();

      queryString = queryString.replace(
        /([~!#$%^*()+`{}\[\]\|;'<>,\/ ])+/g,
        "_"
      );
      // create an object from the url query string after any append queries have been added.
      const data = createObjectFromQuery(queryString);

      // add the entry tags to the data
      if (entryTags.length > 0) {
        data.tag = entryTags;
      }

      // add the entry id to the data
      if (entryIdTag.length > 0) {
        data.entryid = `${entryIdTag}`;
      }

      // add authors to the data
      if (authorTag.length > 0) {
        data.author = authorTag;
      }

      // add the gallery id to the data.
      if (galTag.length > 0) {
        data.gal = this.getGalleryIdTag();
      }

      if (rgProduct.length > 0) {
        data.rg_product = rgProduct;
      }

      if (rgVersion.length > 0) {
        data.rg_version = rgVersion;
      }

      if (rgAux.length > 0) {
        data.rg_aux = rgAux;
      }

      if (rgTopic.length > 0) {
        data.rg_topic = rgTopic;
      }

      if (rg_cat.length > 0) {
        data.rg_cat = rg_cat;
      }

      if (rg_ent.length > 0) {
        data.rg_ent = rg_ent;
      }

      if (rg_ccat.length > 0) {
        data.rg_ccat = rg_ccat;
      }

      if (rg_cent.length > 0) {
        data.rg_cent = rg_cent;
      }

      if (rgPageType.length > 0) {
        data.rg_pagetype = rgPageType;
      }

      if (typeof rgFbwv === 'boolean') {
        data.rg_fbwv = rgFbwv.toString();
      }

      if (rgPv) {
        data.rg_pv = rgPv.toString();
      }

      if (rgCv) {
        data.rg_cv = rgCv.toString();
      }

      this.data = data;
      this.initialized = true;

      resolve(data);
    });
  }

  /**
   * Gets the current key value pairs. Will throw if not initialized first.
   * @returns {object} A has of the query key value pairs
   */
  get() {
    super.get();
    return this.data;
  }

  /**
   * Retrieves tag values from the page. Currently we're looking at the defaultEntryTagKey,
   * i.e. m_entry_tags and the school Ids tag, i.e. m_hssn_schoolids.
   */
  getEntryTags() {
    const { embedConfig, window: { adiData = {} } } = this;
    let tags = [];

    const processTags = (tagString, delimiter = ";", transform = tag => tag) =>
        tagString ? tagString.split(delimiter).map(transform) : [];

    const processSchoolIds = schoolIds =>
        processTags(schoolIds, ",", schoolId => `_${schoolId}_`);

    if (embedConfig.logLevel === undefined && Object.keys(embedConfig).length) {
        tags = tags.concat(processTags(embedConfig.m_entry_tags, ";", tag => tag.replace("@", "")));
        tags = tags.concat(processSchoolIds(embedConfig.m_hssn_schoolids));
        tags = tags.concat(processTags(embedConfig.m_hssn_sport));
    } else {
        const entryTags = adiData.entryTags || this.window.m_entry_tags || "";
        const HSSNSchoolIds = adiData.hssnSchoolIds || this.window.m_hssn_schoolids || "";
        const hssnSport = adiData.hssnSport || this.window.m_hssn_sport || "";

        tags = tags.concat(processTags(entryTags, ";", tag => tag.replace("@", "")));
        tags = tags.concat(processSchoolIds(HSSNSchoolIds));
        tags = tags.concat(processTags(hssnSport));
    }

    return tags;
  }

  /**
   * Retrieves the entry id of the current page and returns it in an array
   */
  getEntryIdTag() {
    const entryids = [];
    const { adiData = {} } = this.window;
    const { m_entry_id: embedEntryId } = this.embedConfig;
    const entryId = adiData.entryId || this.window.m_entry_id || false;

    if (embedEntryId) {
      entryids.push(embedEntryId);
    } else if (entryId) {
      entryids.push(entryId);
    }
    return entryids;
}

  /**
   * Retrieves the article authors of the current page and return it in an array
   */
  getAuthorTag() {
    const { adiData = {} } = this.window;
    const { m_authors: embedAuthors } = this.embedConfig;
    const entryAuthorUsername = (adiData.entryAuthorUsername || "").replace(/\s*\[.*?\]\s*/g, '');

    if (embedAuthors) {
        return embedAuthors.split(",").map(author => author.trim());
    }

    return entryAuthorUsername
        ? entryAuthorUsername.split("|").map(author => author.trim()).filter(Boolean)
        : [];
  }
  /**
   * Retrieves the gallery id tag of the current page and returns it in an array.
   */
  getGalleryIdTag() {
    const gal = [];
    const { adiData = {} } = this.window;
    const { m_gallery_id: embedGalleryId } = this.embedConfig;
    const galleryId = adiData.galleryId || this.window.m_gallery_id || false;

    if (embedGalleryId) {
        gal.push(embedGalleryId);
    } else if (galleryId) {
        gal.push(galleryId);
    }
    return gal;
  }

  /**
   * Retrieves the product type
   */
  getProduct() {
    const rgProducts = [];
    const { adiData = {} } = this.window;
    const { rg_product: embedProduct } = this.embedConfig;
    const rgProduct = adiData.rgProduct || "undefined";

    if (embedProduct) {
      rgProducts.push(embedProduct);
    } else if (rgProduct) {
      rgProducts.push(rgProduct);
    }
    return rgProducts;
  }
  /**
   * Retrieves the version
   */
  getVersion() {
    const rgVersions = [];
    const { adiData = {} } = this.window;
    const { rg_version: embedVersion } = this.embedConfig;
    const adInsertionValue = adiData.pageType === 'cal' ? setAdDensity() : false;
    const rgVersion = adiData.rgVersion || adInsertionValue || false;

    if (embedVersion) {
      rgVersions.push(embedVersion);
    } else if (rgVersion) {
      rgVersions.push(rgVersion);
    }
    return rgVersions;
  }

  /**
   * Retrieves the aux from clavis - REVGEN-778
   * Only get Clavis values on article pages 'cal' - REVGEN-843
   * Updated so these values are the Uid - REVGEN-853
   */
  getAux() {
    const rgAuxs = [];
    const { adiData = {} } = this.window;
    let rgAux = adiData.auxUid || "undefined";

    if (this.embedConfig.rg_auxUid) {
      rgAuxs.push(this.embedConfig.rg_auxUid);
    } else if (rgAux) {
      rgAux = rgAux === 'undefined' || rgAux === "" ? "0" : rgAux;
      rgAuxs.push(rgAux);
    }
    return rgAuxs;
  }

  /**
   * Retrieves the topics from clavis - REVGEN-778
   * Only get Clavis values on article pages 'cal' - REVGEN-843
   * Updated so these values are the Uid - REVGEN-853
   */
  getTopic() {
    const rgTopics = [];
    const { adiData = {} } = this.window;
    let rgTopic = adiData.topicsUid || "undefined";

    if (this.embedConfig.rg_topicUid) {
      rgTopics.push(this.embedConfig.rg_topicUid);
    } else if (rgTopic) {
      rgTopic = rgTopic === 'undefined' || rgTopic === "" ? "0" : rgTopic;
      rgTopics.push(rgTopic);
    }
    return rgTopics;
  }

  /**
   * Retrieves the categories from clavis
   * Only get Clavis values on article pages 'cal'
   * Updated so these values are the Uid
   */
  getCategories() {
    let rgCategory = this.window?.adiData?.arcCategoriesUid ?? "0";
    rgCategory = (rgCategory === 'undefined' || rgCategory === "") ? "0" : rgCategory.replaceAll(";", ",");
    return [rgCategory];
  }

  /**
   * Retrieves the entities from clavis
   * Only get Clavis values on article pages 'cal'
   * Updated so these values are the Uid
   */
  getEntities() {
    let rgEntity = this.window?.adiData?.arcEntitiesUid ?? "0";
    rgEntity = (rgEntity === 'undefined' || rgEntity === "") ? "0" : rgEntity.replaceAll(";", ",");
    return [rgEntity];
  }

  /**
   * Retrieves the custom categories from clavis
   * Only get Clavis values on article pages 'cal'
   * Updated so these values are the Uid
   */
  getCustomCategories() {
    let rgCustomCategory = this.window?.adiData?.arcCustomCategoriesUid ?? "0";
    rgCustomCategory = (rgCustomCategory === 'undefined' || rgCustomCategory === "") ? "0" : rgCustomCategory.replaceAll(";", ",");
    return [rgCustomCategory];
  }

  /**
   * Retrieves the custom entities from clavis
   * Only get Clavis values on article pages 'cal'
   * Updated so these values are the Uid
   */
  getCustomEntities() {
    let rgCustomEntity = this.window?.adiData?.arcCustomEntitiesUid ?? "0";
    rgCustomEntity = (rgCustomEntity === 'undefined' || rgCustomEntity === "") ? "0" : rgCustomEntity.replaceAll(";", ",");
    return [rgCustomEntity];
  }

  /**
   * Retrieves the page type
   */
  getPageType() {
    const rgPageTypes = [];
    const { adiData = {} } = this.window;
    const { rg_pagetype: embedPageType } = this.embedConfig;
    const rgPageType = adiData.pageType || this.window.m_page_type || "undefined";

    if (embedPageType) {
      rgPageTypes.push(embedPageType);
    } else if (rgPageType) {
      rgPageTypes.push(rgPageType);
    }
    return rgPageTypes;
  }

  /**
  * Retrieves if it's the Facebook in-app browser
  */
  getFbwv() {
    // Check for a HTTP User-Agent with the value FB_IAB/FB4A for Android and
    // FBAN/FBIOS for iOS.
    const ua = navigator.userAgent || "";
    const fbRegex = /FB_IAB|FBAN|FBAV/;
    return fbRegex.test(ua);
  }
}

/**
 * Analytics tests - Persistent Value - REVGEN-786
 * In REVGEN-1177, we add a content view counter for cal pages only so we need to separate PV from CV and count them both when needed
 * The changes allow us to use the same function for both values
 */
export const setPersistentKeyValue = (valueName) => {
  adLogger("setPersistentKeyValue", `Setting persistent key value / checking sessionStorage - ${valueName}`, "INFO");
  // if sessionStorage is not available don't do any of this.
  if (typeof window.sessionStorage !== 'undefined') {
    // set valueName
    let value = window.sessionStorage[valueName] || 1;

    // if sessionStorage valueName does not exist, it means that we haven't set any persistent value yet
    if (!window.sessionStorage[valueName]) {
      adLogger("setPersistentKeyValue", `${valueName} does not exist - setting`, "INFO");
      window.sessionStorage.setItem(`initial-${valueName}-time`, new Date());
      window.sessionStorage.setItem(`${valueName}-url`, window.location.href);
      window.sessionStorage.setItem(valueName, value);
    }

    // if valueName does exist but the url is different than what is in sessionStorage, check if it's been 30 min and update data
    if (window.sessionStorage[valueName] && (window.location.href !== window.sessionStorage.getItem(`${valueName}-url`))) {
      adLogger("setPersistentKeyValue", `${valueName} does exist - checking if it's been 30 minutes`, "INFO");
      const initialPVtime = window.sessionStorage.getItem(`initial-${valueName}-time`);
      const latestPVtime = new Date();
      window.sessionStorage.setItem(`latest-${valueName}-time`, latestPVtime);

      // this is how long it's been - if over 1.8e+6 ms, reset the valueName value otherwise add to it
      const diff = Math.abs(new Date(latestPVtime) - new Date(initialPVtime));
      window.sessionStorage.setItem(`${valueName}-diff`, diff);
      window.sessionStorage.setItem(`${valueName}-url`, window.location.href);
      if (diff > 1.8e+6) { // 30 min = 1.8e+6
        window.sessionStorage.setItem(`initial-${valueName}-time`, latestPVtime);
        value = 1;
        window.sessionStorage.setItem(valueName, value);
      } else {
        value = parseInt(value) + 1;
        window.sessionStorage.setItem(valueName, value);
      }
    }
    adLogger("setPersistentKeyValue", `${valueName} equals ${value}`, "INFO");
    return parseInt(value);
  }
}

// REVGEN-1140 - changing counting to BC CDP NPM module
// All affiliates get higher ad density all the time
export const setInsertionMultiple = (adDensityClass, insertionMultiple) => {
  window.rg_insertionMultiple = parseInt(insertionMultiple);
  adLogger("setAdDensity", `Visitor is class ${adDensityClass}, we are setting the page insertion multiple to ${insertionMultiple}`, "INFO");
}

export const setAdDensity = () => {
  adLogger("setAdDensity", `Setting higher ad density for all`, "INFO");
  const platform = getPlatform();
  const adDensityClass = 'infrequentVisitors';
  const insertionMultiple = adDensityMap[adDensityClass][platform];

  setInsertionMultiple(adDensityClass, insertionMultiple);
}

// Sets targeting values for GAM from local storage provided by the BlueConic GAM connection - REVGEN-1019
export const setGAMTargetingFromLocalStorage = (sequence) => {
    const TARGETING_KEY = 'bcDFPTargetingParams';
    const BC_SEGMENTS_KEY = 'bcSegments';
    const GAM_TARGETING_KEY = 'bc';
    const DCR_EXCLUSION_SEGMENT = 'dcr_not_anonymous_low'; // RED-3488
    const NEW_DCR_EXCLUSION_SEGMENT = 'dcr_ads_exclusion' // RED-4293

    adLogger('setGAMTargetingFromLocalStorage', `Checking value of 'sequence': ${sequence}`, 'INFO');

    const bcSegmentsData = getLocalStorageValue(TARGETING_KEY, BC_SEGMENTS_KEY);
    const shortenedSegments = bcSegmentsData ? bcSegmentsData.slice(0, 300).toString() : '';
    const exclusionSegments = [DCR_EXCLUSION_SEGMENT, NEW_DCR_EXCLUSION_SEGMENT];
    const [dcrExclusionExists, newDcrExclusionExists] = exclusionSegments.map(segment => shortenedSegments.includes(segment));

    const isGoogletagAvailable = () => {
        // we check for google here but if it's not available, set up google cache and return true
        if (typeof googletag !== 'undefined' &&
        typeof googletag.pubads !== 'undefined' &&
        typeof googletag.pubads().setTargeting !== 'undefined') {
          return true;
        } else {
          adLogger('isGoogletagAvailable', 'Google not ready, setting up command cache', 'INFO');
          window.googletag = window.googletag || {};
          window.googletag.cmd = window.googletag.cmd || [];
          return true;
        }
    }

    if (isGoogletagAvailable && (newDcrExclusionExists || dcrExclusionExists)) {
        let segments = [];
        if (dcrExclusionExists) {
            segments.push(DCR_EXCLUSION_SEGMENT);
        }
        if (newDcrExclusionExists) {
            segments.push(NEW_DCR_EXCLUSION_SEGMENT);
        }

        const bcValue = segments.join(',');
        const logMessage =
            sequence === 'initial'
                ? `Setting new targeting from Local Storage BC segments: ${bcValue}`
                : `Setting new targeting from Local Storage post BC callback: ${bcValue}`;

        adLogger('setGAMTargetingFromLocalStorage', logMessage, 'INFO');

        googletag.cmd.push(() => {
          googletag.pubads().setTargeting(GAM_TARGETING_KEY, bcValue);
        });

    } else if (isGoogletagAvailable && window.bcDFPCallbackCalled) {
        // pass in 0 only if window.bcDFPCallbackCalled is true
        adLogger(
            'setGAMTargetingFromLocalStorage',
            'No targeting available from BC but window.bcDFPCallbackCalled is true, passing "0" to GAM',
            'INFO'
        );
        adLogger('setGAMTargetingFromLocalStorage', 'Google not ready, setting up command cache', 'INFO');
        googletag.cmd.push(() => {
          googletag.pubads().setTargeting(GAM_TARGETING_KEY, '0');
        });
    }
};

// REVGEN-1075 - Set reg_id with the email_hash from bc
const abbrev = affiliateAbbrev();

const fetchAuth0Id = () => {
  // REVGEN-1253
  // get domain and the corresponding auth0 identifier
  // remove the text before the pipe and set it as the reg_id
  // the promise to grab the profile properties in the CDP module is too slow, we still need direct access below
  const rawRegId = blueConicClient?.profile?.getProfile()?.getValue(`${abbrev}_auth0_identifier`) || '';
  const regId = rawRegId.split("|").pop();
  adLogger("fetchRegId", `Check bc for auth0 identifier value and set reg_id cookie - ${regId}`, "INFO");

  if (regId) {
    localStorage.setItem(`reg_id`, regId);
    adLogger("fetchRegId", `Setting localstorage reg_id to the auth0 id found in BC`, "INFO");
  } else {
    adLogger("fetchRegId", `auth0 id not found in BC`, "INFO");
  }
}

export const fetchRegId = () => {
  adLogger("fetchRegId", "We are waiting for BlueConic", "INFO");
  window.addEventListener('cdpOnEventReady', fetchAuth0Id, false);
}
